import axios from 'axios';

const API_URL = 'https://rhea.gercekisler.com/api';

// Axios instance oluşturma
const instance = axios.create({
    baseURL: API_URL,
});

// Admin giriş fonksiyonu
export const adminLogin = async (credentials) => {
    return instance.post('/auth/admin/login', credentials);
};

// Formu backend'e gönder
export const submitForm = async (data) => {
    return instance.post(`/form/submit`, data);
};

// Veritabanındaki verileri getir (JWT ile)
export const getRegistrations = async (token) => {
    return instance.get(`/form/registrations`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
};

// Excel dosyasını indir (JWT ile)
export const downloadExcel = async (token) => {
    return instance.get(`/form/export`, {
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
};

// Newsletter aboneliği için e-posta kaydı ekle
export const subscribeNewsletter = async (email) => {
    return instance.post(`/form/newsletter/subscribe`, { email });
};

// (İsteğe bağlı) Tüm newsletter abonelerini getir (JWT ile)
export const getNewsletterSubscriptions = async (token) => {
    return instance.get(`/form/newsletter/subscriptions`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
};
