import React from 'react'
import Hero from '../components/Hero/Hero'
import Features2 from '../components/Features2/Features2'
import Score from '../components/Score/Score'
import Features4 from '../components/Features4/Features4'
import Features5 from '../components/Features5/Features5'
import Features6 from '../components/Features6/Features6'
import Family from '../components/Family/Family'
import Pet from '../components/Pet/Pet'
import Professional from '../components/Professional/Professional'
import Mission from '../components/Mission/Mission'
import Register from '../components/Register/Register'
import Footer from '../components/Footer/Footer'

export default function HomePage() {
    return (
        <div>
            <Hero />
            <Features2 />
            <Score />
            <Features4 />
            <Features5 />
            <Features6 />
            <Family />
            <Pet />
            <Professional />
            <Mission />
            <Register />
            <Footer />
        </div>
    )
}
