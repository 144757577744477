import React, { useEffect, useState } from 'react';
import { getRegistrations, downloadExcel, adminLogin } from '../services/api';
import './AdminPage.css';

const AdminPage = () => {
    const [registrations, setRegistrations] = useState([]);
    const [loggedIn, setLoggedIn] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");

    useEffect(() => {
        if (loggedIn && token) {
            const fetchData = async () => {
                try {
                    const response = await getRegistrations(token);
                    setRegistrations(response.data);
                } catch (error) {
                    console.error('Kullanıcıları alırken hata:', error);
                    alert('Kayıtlı kullanıcılar getirilemedi. Lütfen daha sonra tekrar deneyin.');
                }
            };
            fetchData();
        }
    }, [loggedIn, token]);

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await adminLogin({ username, password });
            const receivedToken = response.data.token;
            setToken(receivedToken);
            setLoggedIn(true);
            // Token'ı localStorage'da saklamak isterseniz:
            localStorage.setItem('adminToken', receivedToken);
        } catch (error) {
            console.error('Giriş hatası:', error);
            alert("Hatalı kullanıcı adı veya şifre!");
        }
    };

    const handleDownloadExcel = async () => {
        try {
            const response = await downloadExcel(token);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'registrations.xlsx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Excel dosyasını indirirken hata:', error);
            alert('Excel dosyası indirilemedi. Lütfen daha sonra tekrar deneyin.');
        }
    };

    // Oturum açma durumu sayfa yenilendiğinde korunabilir
    useEffect(() => {
        const storedToken = localStorage.getItem('adminToken');
        if (storedToken) {
            setToken(storedToken);
            setLoggedIn(true);
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('adminToken');
        setToken('');
        setLoggedIn(false);
    };

    if (!loggedIn) {
        return (
            <div className="login-page">
                <h1>Admin Login</h1>
                <form className="login-form" onSubmit={handleLogin}>
                    <div className="form-group">
                        <label>Username</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="login-button">Login</button>
                </form>
            </div>
        );
    }

    return (
        <div className="admin-page">
            <h1>Admin Panel</h1>
            <button onClick={handleLogout} className="logout-button">Logout</button>
            <button className="excel-download-button" onClick={handleDownloadExcel}>
                Download Excel
            </button>
            <table className="admin-table">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Gender</th>
                        <th>Country</th>
                        <th>iPhone Model</th>
                        <th>Smartwatch</th>
                        <th>Motivation</th>
                    </tr>
                </thead>
                <tbody>
                    {registrations.length > 0 ? (
                        registrations.map((reg) => (
                            <tr key={reg.id}>
                                <td>{reg.first_name}</td>
                                <td>{reg.last_name}</td>
                                <td>{reg.gender}</td>
                                <td>{reg.country}</td>
                                <td>{reg.iphone_model}</td>
                                <td>{reg.smartwatch}</td>
                                <td>{reg.motivation}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7">No registrations found.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default AdminPage;
