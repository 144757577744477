// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Form from "./components/Form/Form";
import AdminPage from "./pages/AdminPage";
import HomePage from "./pages/HomePage";
import CookieBanner from "./components/Cookie/CookieBanner";

function App() {
  return (
    <Router>
      <div className="App">
        <CookieBanner />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/form-page" element={<Form />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
