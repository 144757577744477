import React, { useState } from 'react';
import './Footer.css';
import logo from '../../assets/animation.png';
import { subscribeNewsletter } from '../../services/api'; // API fonksiyonunu içe aktarın

export default function Footer() {
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState(null); // 'success' veya 'error'
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    // E-posta girişini yönet
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    // Form gönderimini yönet
    const handleSubscribe = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setStatus(null);
        setMessage('');

        // Basit e-posta doğrulama
        if (!validateEmail(email)) {
            setStatus('error');
            setMessage('Lütfen geçerli bir e-posta adresi girin.');
            setIsSubmitting(false);
            return;
        }

        try {
            await subscribeNewsletter(email); // 'response' kaldırıldı
            setStatus('success');
            setMessage('Aboneliğiniz başarıyla kaydedildi!');
            setEmail(''); // Girişi temizle
        } catch (error) {
            if (error.response) {
                // Sunucudan gelen hata mesajını kullan
                setStatus('error');
                setMessage(error.response.data.message || 'Abonelik kaydedilemedi.');
            } else {
                setStatus('error');
                setMessage('Bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    // Basit e-posta doğrulama fonksiyonu
    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    return (
        <div className='footer-section' id='newsletter'>
            <div className="container">
                <div className="row">
                    <div className="footer-logo-section">
                        <img className='footer-logo' src={logo} alt="RealHealth Logo" />
                        <p className='footer-logo-text'>RealHealth</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 d-flex justify-content-start align-items-center">
                        <ul className="footer-links">
                            <li><a href="/">Home</a></li>
                            <li><a href="/#insights">Features</a></li>
                            <li><a href="/#professional">Professionals</a></li>
                            <li><a href="/#mission">Our Mission</a></li>
                            <li><a href="/#newsletter">Newsletter</a></li>
                            <li><a href="/#register">Apply Early Access</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <div className="footer-right">
                            <p className='footer-subscribe-title'>Sign up for our newsletter</p>
                            <form className="footer-newsletter" onSubmit={handleSubscribe}>
                                <input 
                                    className='footer-input' 
                                    type="email" 
                                    placeholder="Enter your email" 
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                                <button 
                                    className='footer-button' 
                                    type="submit" 
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Subscribing...' : 'Subscribe'}
                                </button>
                            </form>
                            {status === 'success' && (
                                <p className='footer-success-message'>{message}</p>
                            )}
                            {status === 'error' && (
                                <p className='footer-error-message'>{message}</p>
                            )}
                            <p className='footer-subscribe-text'>
                                By subscribing you agree to with our <a href="https://realhealth.solutions/appprivacy.pdf">Privacy Policy</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6 col-sm-12">
                        <ul className="footer-copyright-links">
                            <li><a href="https://realhealth.solutions/appprivacy.pdf">Privacy Policy</a></li>
                            <li><a href="https://realhealth.solutions/user%20agreement.pdf">User Agreement</a></li>
                            <li><a href="https://realhealth.solutions/disclaimer.pdf">Disclaimer</a></li>
                        </ul>
                    </div>
                    <div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
                        <p className='footer-copyright'>© 2024 RealHealth. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}