import React, { useState } from "react";
import "./Navbar.css";
import logo from "../../assets/animation.png";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar-logo">
          <a href="/"> {/* Sayfanın en üstüne yönlendirme */}
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <div className="navbar-toggle" onClick={toggleMenu}>
          ☰ {/* Hamburger menü ikonu */}
        </div>
        <ul className={`navbar-links ${menuOpen ? "active" : ""}`}>
          <li><a href="/">Home</a></li>
          <li><a href="/#insights">Features</a></li>
          <li><a href="/#professional">Professionals</a></li>
          <li><a href="/#mission">Our Mission</a></li>
          <li><a href="/#newsletter">Newsletter</a></li>
          <li><a href="/#register">Apply Early Access</a></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
