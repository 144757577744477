import React from 'react'
import './Features2.css'

export default function Features2() {
  return (
    <div className='features2-section' id='insights'>
        <div className='container'>
            <div className="row">
                <div className="col-md-6">
                    <h1 className='features2-title'>Personalized Insights</h1>
                </div>
                <div className="col-md-6">
                    <h1 className='features2-subtitle'>Health doesn’t have to be complicated.</h1>
                    <p className='features2-text'>Your wearables and IoT devices collect the data, we make it easy to understand. By simplifying complex metrics, we help you see the bigger picture of your health at a glance</p>
                </div>
            </div>
        </div>
    </div>
  )
}
