import React from 'react'
import './Family.css'
import imageLeft from "../../assets/family-mockup.png";
import imageRight from "../../assets/family.png";

export default function Family() {
  return (
    <div className='family-section' id='family'>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <h1 className='family-title'>Friends & Family</h1>
                    <h3 className='family-subtitle'>Wellbeing isn’t a solo journey.</h3>
                    <p className='family-text'>Share your journey with friends and family, motivate each other, and celebrate every achievement along the way.</p>
                    <img className='family-image-left' src={imageLeft} alt="" />
                </div>
                <div className="col-md-6">
                    <img className='family-image-right' src={imageRight} alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}
