import React from 'react'
import './Mission.css'

export default function mission() {
  return (
    <div className='mission-section' id='mission'>
        <div className="container">
            <h1 className='mission-title'>Mission Statement</h1>
            <div className='mission-text-section'>
                <p className='mission-text'>Uniting the legacy of history’s greatest minds through today’s technology.</p>
            </div>
        </div>
    </div>
  )
}
