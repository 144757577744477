import React from 'react';
import './Register.css';
import { useNavigate } from "react-router-dom";

export default function Register() {
  const navigate = useNavigate();

  const handleApplyNowClick = () => {
    navigate("/form-page"); // Form sayfasına yönlendir
    window.scrollTo({
      top: 0, // Sayfanın en üstü
      behavior: "smooth" // Yumuşak kaydırma
    });
  };

  return (
    <div className='register-section' id='register'>
        <div className="container">
            <h1 className='register-title'>Fill out the form for exclusive early access.</h1>
            <p className='register-text'>You can apply for early access by filling out the form. <br />
            This way you can use the app for free.</p>
            <button className='register-button' onClick={handleApplyNowClick}>Start Your Health Journey</button>
        </div>
    </div>
  );
}
