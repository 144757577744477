// src/components/Cookie/CookieBanner.jsx
import React, { useState, useEffect } from 'react';
import './CookieBanner.css';
import { loadScript } from '../../utils/loadScript';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true, // Always enabled
    analytics: true, // Default to accepted
    marketing: true, // Default to accepted
  });

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    } else {
      const parsedConsent = JSON.parse(consent);
      setPreferences(parsedConsent);
      if (parsedConsent.analytics) {
        loadGoogleAnalytics();
      }
      if (parsedConsent.marketing) {
        loadMarketingScripts();
      }
    }
  }, []);

  const handleAcceptAll = () => {
    const newPreferences = {
      ...preferences,
      analytics: true,
      marketing: true,
    };
    localStorage.setItem('cookieConsent', JSON.stringify(newPreferences));
    setPreferences(newPreferences);
    setIsVisible(false);
    loadGoogleAnalytics();
    loadMarketingScripts();
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(preferences));
    setIsVisible(false);
    if (preferences.analytics) {
      loadGoogleAnalytics();
    }
    if (preferences.marketing) {
      loadMarketingScripts();
    }
  };

  const handleDeclineAll = () => {
    const newPreferences = {
      ...preferences,
      analytics: false,
      marketing: false,
    };
    localStorage.setItem('cookieConsent', JSON.stringify(newPreferences));
    setPreferences(newPreferences);
    setIsVisible(false);
  };

  const handleChange = (e) => {
    const { name, checked } = e.target;
    setPreferences((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const loadGoogleAnalytics = async () => {
    try {
      // Define global variables for Google Analytics
      window.dataLayer = window.dataLayer || [];
      window.gtag = function(){ window.dataLayer.push(arguments); };
      window.gtag('js', new Date());
      window.gtag('config', 'G-6SFD4GZQ24');
      
      // Load Google Analytics script
      await loadScript("https://www.googletagmanager.com/gtag/js?id=G-6SFD4GZQ24", "google-analytics");
    } catch (error) {
      console.error("Google Analytics failed to load:", error);
    }
  };

  const loadMarketingScripts = async () => {
    try {
      // --- Meta (Facebook) Pixel ---
      if (!window.fbq) {
        // Define global variable for Facebook Pixel
        window.fbq = function() {
          window.fbq.callMethod ?
            window.fbq.callMethod.apply(window.fbq, arguments) :
            window.fbq.queue.push(arguments);
        };
        window.fbq.queue = [];
        window.fbq.version = '2.0';
      }
      // Load Facebook Pixel script
      await loadScript('https://connect.facebook.net/en_US/fbevents.js', 'facebook-pixel');
      if (window.fbq) {
        window.fbq('init', '450354231398843');
        window.fbq('track', 'PageView');
      }

      // --- TikTok Pixel ---
      if (!window.ttq) {
        // Define global variable for TikTok Pixel
        window.TiktokAnalyticsObject = 'ttq';
        window.ttq = window.ttq || [];
        window.ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie", "holdConsent", "revokeConsent", "grantConsent"];
        window.ttq.setAndDefer = function (t, e) { window.ttq[e] = function () { window.ttq.push([e].concat(Array.prototype.slice.call(arguments, 0))) } };
        for (var i = 0; i < window.ttq.methods.length; i++) {
          window.ttq.setAndDefer(window.ttq, window.ttq.methods[i]);
        }
        window.ttq.instance = function (t) {
          for (
            var e = window.ttq._i[t] || [], n = 0; n < window.ttq.methods.length; n++) {
            window.ttq.setAndDefer(e, window.ttq.methods[n]);
          }
          return e;
        };
        window.ttq.load = function (e, n) {
          var r = "https://analytics.tiktok.com/i18n/pixel/events.js", o = n && n.partner;
          window.ttq._i = window.ttq._i || {};
          window.ttq._i[e] = [];
          window.ttq._i[e]._u = r;
          window.ttq._t = window.ttq._t || {};
          window.ttq._t[e] = +new Date;
          window.ttq._o = window.ttq._o || {};
          window.ttq._o[e] = n || {};
          n = document.createElement("script");
          n.type = "text/javascript";
          n.async = true;
          n.src = r + "?sdkid=" + e + "&lib=ttq";
          var s = document.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(n, s);
        };
      }
      // Load TikTok Pixel script
      await loadScript('https://analytics.tiktok.com/i18n/pixel/events.js', 'tiktok-pixel');
      if (window.ttq) {
        window.ttq.load('CSBAKRJC77UFDI75531G');
        window.ttq.page();
      }

      // --- Snap Pixel ---
      if (!window.snaptr) {
        // Define global variable for Snap Pixel
        window.snaptr = function () {
          window.snaptr.handleRequest ?
            window.snaptr.handleRequest.apply(window.snaptr, arguments) :
            window.snaptr.queue.push(arguments);
        };
        window.snaptr.queue = [];
      }
      // Load Snap Pixel script
      await loadScript('https://sc-static.net/scevent.min.js', 'snap-pixel');
      if (window.snaptr) {
        window.snaptr('init', 'bce7c4b2-df5d-4f91-89dd-9ebd8dce46db', {
          // 'user_email': '__INSERT_USER_EMAIL__' // Replace with actual email or remove if not needed
        });
        window.snaptr('track', 'PAGE_VIEW');
      }

    } catch (error) {
      console.error("Marketing scripts failed to load:", error);
    }
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-banner">
      <p>
        We use cookies on our website. By continuing, you are agreeing to our&nbsp;
        <a href="https://realhealth.solutions/appprivacy.pdf" target='blank' style={{ textDecoration: 'underline', color: '#007bff' }}>
          privacy policy
        </a>
        &nbsp;.
      </p>
      <div className="preferences">
        <label className="switch">
          <input
            type="checkbox"
            name="analytics"
            checked={preferences.analytics}
            onChange={handleChange}
            aria-label="Analytics Cookies"
          />
          <span className="slider"></span>
          <span className="switch-label">Analytics Cookies</span>
        </label>
        <label className="switch">
          <input
            type="checkbox"
            name="marketing"
            checked={preferences.marketing}
            onChange={handleChange}
            aria-label="Marketing Cookies"
          />
          <span className="slider"></span>
          <span className="switch-label">Marketing Cookies</span>
        </label>
      </div>
      <div className="button-container">
        <button className="accept" onClick={handleAcceptAll}>
          Accept All
        </button>
        <button className="save" onClick={handleSavePreferences}>
          Save Preferences
        </button>
        <button className="decline" onClick={handleDeclineAll}>
          Decline
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
