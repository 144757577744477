import React from 'react'
import './Score.css'
import image1 from "../../assets/score-image.png";

export default function Score() {
    return (
        <div className='score-section' id='score'>
            <div className='container'>
                <div className="row">
                    <div className="col-md-12">
                        <h1 className='score-title'>Daily Health Score</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-6 p-0">
                        <h1 className='score-text1'>One score to keep your wellbeing on track.</h1>
                        <p className='score-text2'>Simple, <br /> powerful, <br /> actionable.</p>
                        <h1 className='score-text3-1'>By analyzing your daily activity, sleep patterns, exercise, and more, we provide you with a single score to help you better understand and manage your health.</h1>
                    </div>
                    <div className="col-md-4 col-6 score-image-section">
                        <img className='score-image' src={image1} alt="" />
                    </div>
                    <div className="col-md-4 col-0">
                        <h1 className='score-text3-2'>By analyzing your daily activity, sleep patterns, exercise, and more, we provide you with a single score to help you better understand and manage your health.</h1>
                    </div>
                </div>
            </div>
        </div>
    )
}
