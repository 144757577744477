import React from "react";
import animationImage from "../../assets/animation.webp";
import "./Hero.css";
import Navbar from "../Navbar/Navbar";

const Hero = () => {

  return (
    <>
      <Navbar />
      <section className="hero text-white">
        <div className="container">
          <div className="row align-items-center">
            {/* Sol İçerik */}
            <div className="col-lg-7 col-md-6 col-sm-12 text-lg-start text-center">
              <p className="hero-p1">Flying cars? No. Predictive health data? Yes.</p>
              <h1 className="hero-h1">Meet the Future of Health Care</h1>
              <p className="hero-p2">
                Our mission is to redefine health technology by turning data
                overload into meaningful clarity, being the Super-App that puts
                your health front and center.
              </p>
            </div>
            {/* Sağ İçerik: Gif */}
            <div className="col-lg-5 col-md-6 col-sm-12 d-flex justify-content-center">
              <img className="hero-img" src={animationImage} alt="" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
