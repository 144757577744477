import React from 'react'
import './Features6.css'
import sleepIcon from "../../assets/sleep-icon.png";
import objectImage from "../../assets//SleepMock.png";

export default function Features6() {
  return (
    <div className='features6-section'>
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className='features6-title-section'>
                        <img src={sleepIcon} className='features6-title-icon' alt="" />
                        <h1 className='features6-title'>Sleep Score</h1>
                    </div>
                </div>
            </div>
            <div className="row d-flex align-items-center">
                <div className="col-7">
                    <h1 className='features6-subtitle'>You’ve come a long way from counting sheep</h1>
                    <p className='features6-text'>Great tomorrows start with better nights. By analyzing your sleep stages, duration, and consistency, we provide insights to help you improve rest and wake up ready to take on the day.</p>
                </div>
                <div className="col-5 d-flex">
                    <img src={objectImage} className='features6-image' alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}