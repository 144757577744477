import React from 'react'
import './Pet.css'
import imageLeft from "../../assets/pet-mockup.png";
import imageRight from "../../assets/pet.png";

export default function Pet() {
    return (
        <div className='pet-section' id='furry'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 m-auto">
                        <img className='pet-image-right' src={imageRight} alt="" />
                    </div>
                    <div className="col-md-6">
                        <h1 className='pet-title'>Furry Friends</h1>
                        <h3 className='pet-subtitle'>They count on you.<br />We help you care for them.</h3>
                        <p className='pet-text'>Track your most loved companion's vaccines, monitor allergies, and stay updated on their overall health.</p>
                        <img className='pet-image-left' src={imageLeft} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}
