import React, { useState } from 'react';
import './Form.css';
import { submitForm } from '../../services/api';
import logoMockup from '../../assets/form-mockup.png';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';
import Navbar from '../Navbar/Navbar';
import globalIcon from '../../assets/Global.svg';

export default function Form() {
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    mail: '',
    gender: '',
    country: '',
    iphone_model: '',
    smartwatch: '',
    healthProblems: '',
    motivation: '',
  });

  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Gerekli alan kontrolü
    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.mail ||
      !formData.gender ||
      !formData.country ||
      !formData.iphone_model ||
      !formData.smartwatch
    ) {
      setAlert({ show: true, message: t('requiredFields'), variant: 'danger' });
      return;
    }

    try {
      await submitForm(formData);
      setAlert({ show: true, message: t('successSubmit'), variant: 'success' });
      setFormData({
        first_name: '',
        last_name: '',
        mail: '',
        gender: '',
        country: '',
        iphone_model: '',
        smartwatch: '',
        healthProblems: '',
        motivation: '',
      }); // Formu sıfırla
    } catch (error) {
      console.error(t('submitError'), error);
      setAlert({ show: true, message: `${t('submitError')} ${error.message}`, variant: 'danger' });
    }
  };

  return (
    <>
      <Navbar />
      <div className="form-section">
        <div className="container">
          <div className="row" style={{ paddingTop: "90px", paddingBottom: "10px" }}>
            <div className="col-md-6 d-flex justify-content-center align-items-center m-auto">
              <div>
                <img className='form-mockup-image' src={logoMockup} alt="" />
              </div>
            </div>

            <div className="col-md-6">
              {/* Dil Seçici */}
              <div className="language-selector">
                <img
                  src={globalIcon} // İstediğiniz ikonun URL'sini buraya ekleyin
                  alt="Language"
                  className="language-selector-icon"
                />
                <span className="language-selector-text">
                  {i18n.language.toUpperCase()}
                </span>
                <span className="language-selector-arrow"></span>
                <select
                  id="language-select"
                  onChange={handleLanguageChange}
                  value={i18n.language}
                >
                  <option value="en">EN</option>
                  <option value="tr">TR</option>
                  <option value="de">DE</option>
                  <option value="nl">NL</option>
                </select>
              </div>

              {/* Bildirimler */}
              {alert.show && (
                <div className={`alert alert-${alert.variant} alert-dismissible fade show`} role="alert">
                  {alert.message}
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setAlert({ show: false })}
                  ></button>
                </div>
              )}

              <form className="register-form" onSubmit={handleSubmit}>
                <h2 className="form-personal-title">{t('personalInfo')}</h2>
                <div className="form-group">
                  <label>{t('firstName')}</label>
                  <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    placeholder={t('firstNamePlaceholder')}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>{t('lastName')}</label>
                  <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    placeholder={t('lastNamePlaceholder')}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>{t('email')}</label>
                  <input
                    type="email"
                    name="mail"
                    value={formData.mail}
                    onChange={handleChange}
                    placeholder={t('emailPlaceholder')}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>{t('gender')}</label>
                  <div className="gender-options">
                    <label className="gender-option">
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        checked={formData.gender === "male"}
                        onChange={handleChange}
                        required
                      />
                      <span>{t('male')}</span>
                    </label>
                    <label className="gender-option">
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        checked={formData.gender === "female"}
                        onChange={handleChange}
                        required
                      />
                      <span>{t('female')}</span>
                    </label>
                  </div>
                </div>

                <div className="form-group">
                  <label>{t('country')}</label>
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  >
                    <option value="">{t('selectCountry')}</option>
                    <option value="afghanistan">Afghanistan</option>
                    <option value="albania">Albania</option>
                    <option value="algeria">Algeria</option>
                    <option value="andorra">Andorra</option>
                    <option value="angola">Angola</option>
                    <option value="antigua-and-barbuda">Antigua and Barbuda</option>
                    <option value="argentina">Argentina</option>
                    <option value="armenia">Armenia</option>
                    <option value="australia">Australia</option>
                    <option value="austria">Austria</option>
                    <option value="azerbaijan">Azerbaijan</option>
                    <option value="bahamas">Bahamas</option>
                    <option value="bahrain">Bahrain</option>
                    <option value="bangladesh">Bangladesh</option>
                    <option value="barbados">Barbados</option>
                    <option value="belarus">Belarus</option>
                    <option value="belgium">Belgium</option>
                    <option value="belize">Belize</option>
                    <option value="benin">Benin</option>
                    <option value="bhutan">Bhutan</option>
                    <option value="bolivia">Bolivia</option>
                    <option value="bosnia-and-herzegovina">Bosnia and Herzegovina</option>
                    <option value="botswana">Botswana</option>
                    <option value="brazil">Brazil</option>
                    <option value="brunei">Brunei</option>
                    <option value="bulgaria">Bulgaria</option>
                    <option value="burkina-faso">Burkina Faso</option>
                    <option value="burundi">Burundi</option>
                    <option value="cambodia">Cambodia</option>
                    <option value="cameroon">Cameroon</option>
                    <option value="canada">Canada</option>
                    <option value="cape-verde">Cape Verde</option>
                    <option value="central-african-republic">Central African Republic</option>
                    <option value="chad">Chad</option>
                    <option value="chile">Chile</option>
                    <option value="china">China</option>
                    <option value="colombia">Colombia</option>
                    <option value="comoros">Comoros</option>
                    <option value="congo">Congo</option>
                    <option value="costarica">Costa Rica</option>
                    <option value="croatia">Croatia</option>
                    <option value="cuba">Cuba</option>
                    <option value="cyprus">Cyprus</option>
                    <option value="czech-republic">Czech Republic</option>
                    <option value="denmark">Denmark</option>
                    <option value="djibouti">Djibouti</option>
                    <option value="dominica">Dominica</option>
                    <option value="dominican-republic">Dominican Republic</option>
                    <option value="ecuador">Ecuador</option>
                    <option value="egypt">Egypt</option>
                    <option value="el-salvador">El Salvador</option>
                    <option value="equatorial-guinea">Equatorial Guinea</option>
                    <option value="eritrea">Eritrea</option>
                    <option value="estonia">Estonia</option>
                    <option value="eswatini">Eswatini</option>
                    <option value="ethiopia">Ethiopia</option>
                    <option value="fiji">Fiji</option>
                    <option value="finland">Finland</option>
                    <option value="france">France</option>
                    <option value="gabon">Gabon</option>
                    <option value="gambia">Gambia</option>
                    <option value="georgia">Georgia</option>
                    <option value="germany">Germany</option>
                    <option value="ghana">Ghana</option>
                    <option value="greece">Greece</option>
                    <option value="grenada">Grenada</option>
                    <option value="guatemala">Guatemala</option>
                    <option value="guinea">Guinea</option>
                    <option value="guinea-bissau">Guinea-Bissau</option>
                    <option value="guyana">Guyana</option>
                    <option value="haiti">Haiti</option>
                    <option value="honduras">Honduras</option>
                    <option value="hungary">Hungary</option>
                    <option value="iceland">Iceland</option>
                    <option value="india">India</option>
                    <option value="indonesia">Indonesia</option>
                    <option value="iran">Iran</option>
                    <option value="iraq">Iraq</option>
                    <option value="ireland">Ireland</option>
                    <option value="israel">Israel</option>
                    <option value="italy">Italy</option>
                    <option value="jamaica">Jamaica</option>
                    <option value="japan">Japan</option>
                    <option value="jordan">Jordan</option>
                    <option value="kazakhstan">Kazakhstan</option>
                    <option value="kenya">Kenya</option>
                    <option value="kiribati">Kiribati</option>
                    <option value="kuwait">Kuwait</option>
                    <option value="kyrgyzstan">Kyrgyzstan</option>
                    <option value="laos">Laos</option>
                    <option value="latvia">Latvia</option>
                    <option value="lebanon">Lebanon</option>
                    <option value="lesotho">Lesotho</option>
                    <option value="liberia">Liberia</option>
                    <option value="libya">Libya</option>
                    <option value="liechtenstein">Liechtenstein</option>
                    <option value="lithuania">Lithuania</option>
                    <option value="luxembourg">Luxembourg</option>
                    <option value="madagascar">Madagascar</option>
                    <option value="malawi">Malawi</option>
                    <option value="malaysia">Malaysia</option>
                    <option value="maldives">Maldives</option>
                    <option value="mali">Mali</option>
                    <option value="malta">Malta</option>
                    <option value="marshall-islands">Marshall Islands</option>
                    <option value="mauritania">Mauritania</option>
                    <option value="mauritius">Mauritius</option>
                    <option value="mexico">Mexico</option>
                    <option value="micronesia">Micronesia</option>
                    <option value="moldova">Moldova</option>
                    <option value="monaco">Monaco</option>
                    <option value="mongolia">Mongolia</option>
                    <option value="montenegro">Montenegro</option>
                    <option value="morocco">Morocco</option>
                    <option value="mozambique">Mozambique</option>
                    <option value="myanmar">Myanmar</option>
                    <option value="namibia">Namibia</option>
                    <option value="nauru">Nauru</option>
                    <option value="nepal">Nepal</option>
                    <option value="netherlands">Netherlands</option>
                    <option value="new-zealand">New Zealand</option>
                    <option value="nicaragua">Nicaragua</option>
                    <option value="niger">Niger</option>
                    <option value="nigeria">Nigeria</option>
                    <option value="north-korea">North Korea</option>
                    <option value="north-macedonia">North Macedonia</option>
                    <option value="norway">Norway</option>
                    <option value="oman">Oman</option>
                    <option value="pakistan">Pakistan</option>
                    <option value="palau">Palau</option>
                    <option value="palestine">Palestine</option>
                    <option value="panama">Panama</option>
                    <option value="papua-new-guinea">Papua New Guinea</option>
                    <option value="paraguay">Paraguay</option>
                    <option value="peru">Peru</option>
                    <option value="philippines">Philippines</option>
                    <option value="poland">Poland</option>
                    <option value="portugal">Portugal</option>
                    <option value="qatar">Qatar</option>
                    <option value="romania">Romania</option>
                    <option value="russia">Russia</option>
                    <option value="rwanda">Rwanda</option>
                    <option value="saint-kitts-and-nevis">Saint Kitts and Nevis</option>
                    <option value="saint-lucia">Saint Lucia</option>
                    <option value="saint-vincent-and-the-grenadines">Saint Vincent and the Grenadines</option>
                    <option value="samoa">Samoa</option>
                    <option value="san-marino">San Marino</option>
                    <option value="sao-tome-and-principe">Sao Tome and Principe</option>
                    <option value="saudi-arabia">Saudi Arabia</option>
                    <option value="senegal">Senegal</option>
                    <option value="serbia">Serbia</option>
                    <option value="seychelles">Seychelles</option>
                    <option value="sierra-leone">Sierra Leone</option>
                    <option value="singapore">Singapore</option>
                    <option value="slovakia">Slovakia</option>
                    <option value="slovenia">Slovenia</option>
                    <option value="solomon-islands">Solomon Islands</option>
                    <option value="somalia">Somalia</option>
                    <option value="south-africa">South Africa</option>
                    <option value="south-korea">South Korea</option>
                    <option value="south-sudan">South Sudan</option>
                    <option value="spain">Spain</option>
                    <option value="sri-lanka">Sri Lanka</option>
                    <option value="sudan">Sudan</option>
                    <option value="suriname">Suriname</option>
                    <option value="sweden">Sweden</option>
                    <option value="switzerland">Switzerland</option>
                    <option value="syria">Syria</option>
                    <option value="taiwan">Taiwan</option>
                    <option value="tajikistan">Tajikistan</option>
                    <option value="tanzania">Tanzania</option>
                    <option value="thailand">Thailand</option>
                    <option value="timor-leste">Timor-Leste</option>
                    <option value="togo">Togo</option>
                    <option value="tonga">Tonga</option>
                    <option value="trinidad-and-tobago">Trinidad and Tobago</option>
                    <option value="tunisia">Tunisia</option>
                    <option value="turkey">Turkey</option>
                    <option value="turkmenistan">Turkmenistan</option>
                    <option value="tuvalu">Tuvalu</option>
                    <option value="uganda">Uganda</option>
                    <option value="ukraine">Ukraine</option>
                    <option value="united-arab-emirates">United Arab Emirates</option>
                    <option value="united-kingdom">United Kingdom</option>
                    <option value="united-states">United States</option>
                    <option value="uruguay">Uruguay</option>
                    <option value="uzbekistan">Uzbekistan</option>
                    <option value="vanuatu">Vanuatu</option>
                    <option value="vatican-city">Vatican City</option>
                    <option value="venezuela">Venezuela</option>
                    <option value="vietnam">Vietnam</option>
                    <option value="yemen">Yemen</option>
                    <option value="zambia">Zambia</option>
                    <option value="zimbabwe">Zimbabwe</option>

                  </select>
                </div>
                <h2 className="form-personal-title">{t('technologicalDevices')}</h2>
                <div className="form-group">
                  <label>{t('iphoneModel')}</label>
                  <select
                    name="iphone_model"
                    value={formData.iphone_model}
                    onChange={handleChange}
                    required
                  >
                    <option value="">{t('selectiPhoneModel')}</option>
                    <option value="iphone-xs">iPhone XS</option>
                    <option value="iphone-xs-max">iPhone XS Max</option>
                    <option value="iphone-11">iPhone 11</option>
                    <option value="iphone-11-pro">iPhone 11 Pro</option>
                    <option value="iphone-11-pro-max">iPhone 11 Pro Max</option>
                    <option value="iphone-se-2">iPhone SE (2. Nesil)</option>
                    <option value="iphone-12">iPhone 12</option>
                    <option value="iphone-12-mini">iPhone 12 mini</option>
                    <option value="iphone-12-pro">iPhone 12 Pro</option>
                    <option value="iphone-12-pro-max">iPhone 12 Pro Max</option>
                    <option value="iphone-13">iPhone 13</option>
                    <option value="iphone-13-mini">iPhone 13 mini</option>
                    <option value="iphone-13-pro">iPhone 13 Pro</option>
                    <option value="iphone-13-pro-max">iPhone 13 Pro Max</option>
                    <option value="iphone-se-3">iPhone SE (3. Nesil)</option>
                    <option value="iphone-14">iPhone 14</option>
                    <option value="iphone-14-plus">iPhone 14 Plus</option>
                    <option value="iphone-14-pro">iPhone 14 Pro</option>
                    <option value="iphone-14-pro-max">iPhone 14 Pro Max</option>
                    <option value="iphone-15">iPhone 15</option>
                    <option value="iphone-15-plus">iPhone 15 Plus</option>
                    <option value="iphone-15-pro">iPhone 15 Pro</option>
                    <option value="iphone-15-pro-max">iPhone 15 Pro Max</option>
                    <option value="iphone-15">iPhone 16</option>
                    <option value="iphone-15-plus">iPhone 16 Plus</option>
                    <option value="iphone-15-pro">iPhone 16 Pro</option>
                    <option value="iphone-15-pro-max">iPhone 16 Pro Max</option>

                  </select>
                </div>
                <div className="form-group">
                  <label>{t('smartwatch')}</label>
                  <select
                    name="smartwatch"
                    value={formData.smartwatch}
                    onChange={handleChange}
                    required
                  >
                    <option value="">{t('selectSmartwatch')}</option>
                    {/* <!-- Apple Watch Modelleri --> */}
                    <option value="apple-watch-series-5">Apple Watch Series 5</option>
                    <option value="apple-watch-series-6">Apple Watch Series 6</option>
                    <option value="apple-watch-series-7">Apple Watch Series 7</option>
                    <option value="apple-watch-series-8">Apple Watch Series 8</option>
                    <option value="apple-watch-se">Apple Watch SE</option>
                    <option value="apple-watch-ultra">Apple Watch Ultra</option>
                    <option value="apple-watch-series-9">Apple Watch Series 9</option>
                    <option value="apple-watch-series-10">Apple Watch Series 10</option>
                    <option value="apple-watch-ultra-2">Apple Watch Ultra 2</option>

                    {/* <!-- Samsung Galaxy Watch Modelleri --> */}
                    <option value="samsung-galaxy-watch4">Samsung Galaxy Watch4</option>
                    <option value="samsung-galaxy-watch4-classic">Samsung Galaxy Watch4 Classic</option>
                    <option value="samsung-galaxy-watch5">Samsung Galaxy Watch5</option>
                    <option value="samsung-galaxy-watch5-pro">Samsung Galaxy Watch5 Pro</option>
                    <option value="samsung-galaxy-watch6">Samsung Galaxy Watch6</option>
                    <option value="samsung-galaxy-watch6-classic">Samsung Galaxy Watch6 Classic</option>
                    <option value="samsung-galaxy-watch7">Samsung Galaxy Watch7</option>
                    <option value="samsung-galaxy-watch7-classic">Samsung Galaxy Watch7 Classic</option>
                    <option value="samsung-galaxy-watch-active2">Samsung Galaxy Watch Active2</option>
                    <option value="samsung-galaxy-watch-active3">Samsung Galaxy Watch Active3</option>
                    <option value="samsung-galaxy-watch5-pro">Samsung Galaxy Watch5 Pro</option>

                    {/* <!-- Samsung Galaxy Fit Modelleri --> */}
                    <option value="samsung-galaxy-fit">Samsung Galaxy Fit</option>
                    <option value="samsung-galaxy-fit2">Samsung Galaxy Fit2</option>
                    <option value="samsung-galaxy-fit3">Samsung Galaxy Fit3</option>
                    <option value="samsung-galaxy-fit4">Samsung Galaxy Fit4</option>

                    {/* <!-- Huawei Watch Modelleri --> */}
                    <option value="huawei-watch-gt-3">Huawei Watch GT 3</option>
                    <option value="huawei-watch-gt-3-pro">Huawei Watch GT 3 Pro</option>
                    <option value="huawei-watch-gt-2-pro">Huawei Watch GT 2 Pro</option>
                    <option value="huawei-watch-4-pro">Huawei Watch 4 Pro</option>
                    <option value="huawei-watch-gt-2-pro">Huawei Watch GT 5 Pro</option>
                    <option value="huawei-watch-gt-5">Huawei Watch GT 5</option>
                    <option value="huawei-watch-gt-3-elite">Huawei Watch GT 3 Elite</option>
                    <option value="huawei-watch-fit">Huawei Watch Fit</option>
                    <option value="huawei-watch-fit-2">Huawei Watch Fit 2</option>
                    <option value="huawei-watch-fit-3">Huawei Watch Fit 3</option>
                    <option value="huawei-watch-d">Huawei Watch D</option>

                    {/* <!-- Huawei Band Modelleri --> */}
                    <option value="huawei-band-6">Huawei Band 6</option>
                    <option value="huawei-band-6-pro">Huawei Band 6 Pro</option>
                    <option value="huawei-band-5e">Huawei Band 5e</option>

                    {/* <!-- Xiaomi Mi Watch Modelleri --> */}
                    <option value="xiaomi-mi-watch">Xiaomi Mi Watch</option>
                    <option value="xiaomi-mi-watch-lite">Xiaomi Mi Watch Lite</option>
                    <option value="xiaomi-mi-watch-revolve">Xiaomi Mi Watch Revolve</option>
                    <option value="xiaomi-mi-watch-revolve-active">Xiaomi Mi Watch Revolve Active</option>
                    <option value="xiaomi-mi-watch-color">Xiaomi Mi Watch Color</option>
                    <option value="xiaomi-mi-watch-s1">Xiaomi Mi Watch S1</option>
                    <option value="xiaomi-mi-watch-s1-active">Xiaomi Mi Watch S1 Active</option>
                    <option value="xiaomi-mi-watch-s2">Xiaomi Mi Watch S2</option>
                    <option value="xiaomi-mi-watch-s3">Xiaomi Mi Watch S3</option>
                    <option value="xiaomi-mi-watch-2-pro">Xiaomi Mi Watch 2 Pro</option>


                    {/* <!-- Xiaomi Mi Smart Band Modelleri --> */}
                    <option value="xiaomi-mi-smart-band-7">Xiaomi Mi Smart Band 7</option>
                    <option value="xiaomi-mi-smart-band-7-pro">Xiaomi Mi Smart Band 7 Pro</option>
                    <option value="xiaomi-mi-smart-band-8">Xiaomi Mi Smart Band 8</option>

                    {/* Redmi Watch Modelleri  */}
                    <option value="redmi-watch-2">Redmi Watch 2</option>
                    <option value="redmi-watch-3">Redmi Watch 3</option>
                    <option value="redmi-watch-4">Redmi Watch 4</option>
                    <option value="redmi-watch-5">Redmi Watch 5</option>
                    <option value="redmi-watch-5-lite">Redmi Watch 5 Lite</option>
                    <option value="redmi-watch-5-active">Redmi Watch 5 Active</option>
                    <option value="redmi-watch-3-lite">Redmi Watch 3 Lite</option>
                    <option value="redmi-watch-2-lite">Redmi Watch 2 Lite</option>
                    <option value="redmi-watch-s2">Redmi Watch S2</option>

                    {/* <!-- Redmi Band Modelleri --> */}
                    <option value="redmi-band-7">Redmi Band 7</option>
                    <option value="redmi-band-7-pro">Redmi Band 7 Pro</option>
                    <option value="redmi-band-8">Redmi Band 8</option>
                  </select>
                </div>
                <div className="form-group">
                  <h2 className="form-personal-title pt-3">{t('healthProblems')}</h2>
                  <textarea
                    name="healthProblems"
                    value={formData.healthProblems}
                    onChange={handleChange}
                    placeholder={t('healthPlaceholder')}
                    rows="4"
                  />
                </div>
                <div className="form-group">
                  <h2 className="form-personal-title pt-3">{t('motivation')}</h2>
                  <textarea
                    name="motivation"
                    value={formData.motivation}
                    onChange={handleChange}
                    placeholder={t('motivationPlaceholder')}
                    rows="4"
                  />
                </div>
                <p className='footer-subscribe-text'>
                  {t('privacyPolicyText')} <a href="https://realhealth.solutions/appprivacy.pdf">{t('privacyPolicy')}</a>
                </p>
                <button type="submit" className="form-register-button">
                  {t('apply')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
