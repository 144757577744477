import React from 'react'
import './Professional.css'

export default function Professional() {
  return (
    <div className='prof-section' id='professional'>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6">
                    <h1 className='prof-title'>  </h1>
                </div>
                <div className="col-md-6 prof-right">
                    <h1 className='prof-title'>Professional’s Panel</h1>
                    <h3 className='prof-subtitle'>Your health story, easily shared with those who guide it</h3>
                    <p className='prof-text'>Easily share medical records and health information with your healthcare providers with just a few taps. Stay up to date with notifications from the professionals you follow, ensuring seamless communication and personalized care tailored to your needs.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
