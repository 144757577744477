import React from 'react'
import './Features4.css'
import activityIcon from "../../assets/activity-icon.svg";
import objectImage from "../../assets/ActivityMock.png";

export default function Features4() {
  return (
    <div className='features4-section'>
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className='features4-title-section'>
                        <img src={activityIcon} className='features4-title-icon' alt="" />
                        <h1 className='features4-title'>Activity Score</h1>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-7 d-flex flex-column justify-content-center">
                    <h1 className='features4-subtitle'>How you move defines how you grow</h1>
                    <p className='features4-text'>A score that translates your daily activity into tangible results to help you build endurance, increase energy, and maintain long-term physical health.</p>
                </div>
                <div className="col-5 d-flex">
                    <img src={objectImage} className='features4-image' alt="" />
                </div>
            </div>
        </div>
    </div>
  )
}
