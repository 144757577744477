import React from 'react'
import './Features5.css'
import heartIcon from "../../assets/heart-icon.svg";
import objectImage from "../../assets/HearthMock.png";

export default function Features5() {
  return (
    <div className='features5-section'>
        <div className='container'>
            <div className="row">
                <div className="col-12">
                    <div className='features5-title-section'>
                        <h1 className='features5-title'>Cardio Score</h1>
                        <img src={heartIcon} className='features5-title-icon' alt="" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-5 d-flex justify-content-end">
                    <img src={objectImage} className='features5-image' alt="" />
                </div>
                <div className="col-7 d-flex flex-column justify-content-center">
                    <h1 className='features5-subtitle'>Your heart does the work<br />We give you the roadmap</h1>
                    <p className='features5-text'>We track key metrics like resting heart rate, HRV, and cardio recovery to help you improve your cardiovascular health.</p>
                </div>
            </div>
        </div>
    </div>
  )
}
